<template>
  <div>
    <the-main-view>
      <router-view></router-view>
    </the-main-view>
  </div>
</template>

<script>
export default {};
</script>
